@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-5xl md:text-8xl font-bold;
  }

  h2 {
    @apply text-4xl md:text-6xl font-semibold;
  }

  h3 {
    @apply text-3xl md:text-5xl font-medium;
  }

  h4 {
    @apply text-2xl md:text-4xl font-medium;
  }

  h5 {
    @apply text-xl md:text-3xl font-medium;
  }

  h6 {
    @apply text-lg md:text-2xl font-medium;
  }

  /* Basis Textgröße und Farben für Absätze */
  p {
    @apply text-base md:text-lg leading-relaxed;
  }
}

.standardPadding {
  @apply px-8 md:px-20 lg:px-48 xl:px-72 2xl:px-80 2.5xl:px-[410px];
}

/* Google FontSizes destroy Layout otherwise */
html {
  font-size: 16px; /* oder in %: 100% */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}